import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Element } from "react-scroll"
import { darken } from "polished"

import Layout from "../components/layouts/Layout"
import { Color, Media, SEO, Wrapper } from "../components/utilities"
import { Banner, Section, SectionGrid, Button } from "../components/elements"
import { LinkSmooth } from "../components/layouts/Navbar"

import banner1 from "../images/banner1.jpg"
import jason1 from "../images/jlowder.jpg"
import jason2 from "../images/jason-2.jpg"
import theperson from "../images/cruise.jpg"
import headshot from "../images/headshot.jpg"
import boardroom from "../images/boardroom.jpg"

import fm from "../images/family1.jpg"
import passion from "../images/passion2.jpg"
import jeremiah from "../images/jeremiahs.jpg"
import pro from "../images/pro.jpg"
import balance from "../images/balance-logo.jpg"

import marcos from "../images/marcos.png"
import lennar from "../images/lennar.png"
import sw from "../images/sherwin-williams.jpg"
import tony from "../images/tony-packos.png"
import jere from "../images/jeremiahs.png"

import speaker from "../images/speaker.jpg"

const Quote = styled.div`
  padding: 2rem;
  font-style: italic;
`

const Grid = styled.div`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 100%;
  display: grid;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const LogoGrid = styled.div`
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  > div {
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    ${Media.below.tablet`
      width: 50%;
      margin-left: 0;
      margin-right: 0;
    `}

    img {
      max-width: 125px;
    }
  }
`

const GridCol = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 400px 100px;

  img {
    width: 100%;
  }

  ${Media.below.tablet`
    grid-template-rows: 200px 60px;
  `}
`

const BannerImage = styled.div`
  background: ${props => `url(${props.image})` || Color.grey};
  background-size: cover;
  background-position: center center;
`

const PersonBannerImage = styled(BannerImage)`
  ${Media.below.tablet`
    background-position: center -18px;
  `}
`

const ProBannerImage = styled(BannerImage)`
  ${Media.below.tablet`
    background-position: center -35px;
  `}
`

const BannerOverlay = styled(LinkSmooth)`
  display: block;
  background-color: ${Color.grey};
  color: ${Color.white};
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  border-left: 2px solid ${Color.white};
  border-right: 2px solid ${Color.white};
  text-decoration: none;
  transition: all 0.4s ease-in-out;

  &:hover {
    text-decoration: none !important;
    background-color: ${darken(0.2, Color.grey)};
  }

  h2 {
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;
  }

  ${Media.below.tablet`
    height: 60px;
    border: none;
    line-height: 100%;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.25rem;
    }

    h3 {
      font-size: 1rem;
    }
  `}
`

const IndexBanner = () => (
  <Banner height="auto">
    <Grid>
      <GridCol>
        <PersonBannerImage image={theperson} />
        <BannerOverlay name="person">
          <h3>The</h3>
          <h2>Person</h2>
        </BannerOverlay>
      </GridCol>
      <GridCol>
        <BannerImage image={passion} />
        <BannerOverlay name="passion">
          <h3>The</h3>
          <h2>Passion</h2>
        </BannerOverlay>
      </GridCol>
      <GridCol>
        <ProBannerImage image={boardroom} />
        <BannerOverlay name="pro">
          <h3>The</h3>
          <h2>Professional</h2>
        </BannerOverlay>
      </GridCol>
      <GridCol>
        <BannerImage image={banner1} />
        <BannerOverlay name="public">
          <h3>The Public</h3>
          <h2>Speaker</h2>
        </BannerOverlay>
      </GridCol>
    </Grid>
  </Banner>
)

const IndexPage = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        query SocialQuery {
          site {
            siteMetadata {
              social {
                facebook
                linkedin
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <SEO title="Jason Lowder - Building Better Relationships" />
          <IndexBanner />
          <Element name="jasonlowder">
            <Section>
              <SectionGrid>
                <div>
                  <div className="text-center">
                    <h2 style={{ marginBottom: "2rem" }}>Jason Lowder</h2>
                  </div>
                  <p>
                    “When you do the right things for the right reason, you get
                    the right results.”
                    <br />- Jason Lowder
                    <br />
                    <br />
                    Jason is fired up about making this world a better place
                    through better relationships and more meaningful human
                    interactions, healthier partnerships, bigger smiles and
                    simply just loving one another as we’re supposed to.
                    <br />
                    <br />
                    “I’m not passing this off to the next generation; why put
                    off what we can change now?”
                    <br />
                    <br />
                    Jason is focused on changing the world one relationship at a
                    time. It’s not a gimmick, it’s a passion. And each and every
                    one of us has a part to play in building better
                    relationships. The good ones make us better people, and this
                    world a better place.
                    <br />
                    <br />
                    Start interacting with one another - we need to put the
                    devices down for a minute and start to look each other in
                    the eyes so the smart phones don't make us dumb.
                  </p>
                  <br />
                  <Button modifiers={["grey"]} to="contact">
                    Learn More
                  </Button>
                </div>
                <div className="text-right">
                  <img src={headshot} />
                </div>
              </SectionGrid>
            </Section>
          </Element>

          <Element name="person">
            <Section modifiers="grey">
              <div className="text-center">
                <h2>The Person</h2>
                <h3>Family First</h3>
              </div>
              <SectionGrid modifiers="third1x2">
                <div>
                  <img src={fm} />
                </div>
                <div>
                  <p>
                    Jason was born and raised just outside of Seattle,
                    Washington where he lived with his family through high
                    school. After college, Jason relocated to Waikiki and found
                    a hidden talent on the microphone hosting and emceeing
                    events of all types. That skill took him all over the world
                    hosting events, introducing contestants, emceeing parties,
                    speaking to large crowds, motivating and presenting to
                    businesses and organizations of all types. Jason’s career
                    evolved into a marketing career starting with event and
                    college marketing and lead him to a new business focused on
                    grassroots and guerrilla marketing where he has been
                    delivering positive results for other companies since early
                    2000.
                    <br />
                    <br />
                    Jason's private life is full of Love, including a fun-loving
                    and talented blended family that welcomed his amazing wife,
                    Monika, who he married 2011. Monika and Jason are equally
                    yoked, 3 strands that cannot be broken, his ying to her
                    yang, a solid combination that perfectly complements one
                    another. Monika has become Jason’s business partner, better
                    half, travel partner and keeps him grounded and focused on
                    the mission; Changing the world one relationship at a time.
                    Oh, and they are mom and dad to a rescued Shepard mix named
                    Wiley a.k.a Smiley Wiley. Most definitely man’s best friend!
                  </p>
                </div>
              </SectionGrid>
            </Section>
          </Element>

          <Element name="passion">
            <Section modifiers={["light"]}>
              <div className="text-center">
                <h2>The Passion</h2>
                <h3>Lowder Relationships</h3>
              </div>

              <SectionGrid modifiers="third2x1">
                <div>
                  <h4>Making the World a better place</h4>
                  <p>
                    We truly believe we can make the world a better place one
                    relationship at a time.
                  </p>

                  <h4>Building relationships is better than selling</h4>
                  <p>
                    Good relationships last forever where a sales pitch lasts
                    but for a moment and typical advertising fades over time.
                  </p>

                  <h4>Spray and Pray or Laser Focused</h4>
                  <p>
                    Relationship marketing is strategic and specific like a
                    rifle, known for pen-point accuracy where other marketing
                    methods are like a shot gun and known for the spray and pray
                    method. (Pray and Hope).
                  </p>

                  <h4>Sometimes it's this easy</h4>
                  <p>
                    When you do the right thing for the right reason you get the
                    right results
                  </p>

                  <h4>Balance</h4>
                  <p>
                    We're not saying that all other marketing and advertising is
                    bad, we're just saying you need balance and the foundation
                    should be build on solid local relationships.
                  </p>

                  <h4>Relationships last forever</h4>
                  <p>
                    What do you know of that lasts forever? Ads fade,
                    commercials run their course, print becomes garbage or
                    recycle material, social and digital are gone in the blink
                    of an eye, and all require, pouring endless amounts of money
                    in each and every month
                  </p>
                  <br />
                  <a
                    href="http://lowderrelationships.com"
                    target="_blank"
                    rel="noopner noreferrer"
                  >
                    Find Out More About Lowder Relationships Clicking Here
                  </a>
                </div>
                <div className="text-right">
                  <img src={jeremiah} />
                </div>
              </SectionGrid>
            </Section>
          </Element>
          <Element name="pro">
            <Section>
              <div className="text-center">
                <h2>The Professional</h2>
                <h3>Relationship Marketing</h3>
              </div>
              <SectionGrid modifiers="third2x1">
                <div>
                  <p>
                    This professional entrepreneur loves business and is focused
                    on doing his part to make this world a better place by
                    teaching people how to build strategic business
                    relationships.
                    <br />
                    <br />
                    As the zealous owner of FixAim Marketing and former
                    marketing executive with Marco’s Franchising LLC in addition
                    to being an active franchisee, Jason also sits on the Board
                    of Directors with the non-profit AV7 Foundation{" "}
                    <a
                      href="http://www.AV7.org"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.AV7.org
                    </a>
                    . Currently Jason is advising several organizations as a
                    growth and marketing consultant, a few clients are listed
                    below.
                    <br />
                    <br />
                    In 2019, Jason decided to dedicate himself to doing what he
                    loves while making a bigger impact on the world by launching{" "}
                    <a
                      href="http://lowderrelationships.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.LowderRelationships.com
                    </a>
                    . This allows him to utilize over 25 years of marketing
                    experience to deliver a platform for teaching Relationship
                    Marketing that is focused on building strong local business
                    relationships that drive ever increasing traffic.
                    <br />
                    <br />
                    In 2010 Marco’s Pizza set its sights on recruiting Jason
                    while he was still providing consulting services to the
                    brand through his company,{" "}
                    <a
                      href="http://fixaimmarketing.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.FixAimMarketing.com
                    </a>
                    , a national, guerilla-style, grassroots marketing company
                    that continues to grow even today. With a proven track
                    record of clients ranging from Fortune 100 giants and
                    national brands, to young brands with multiple locations and
                    new companies ready to launch their businesses, FixAim has a
                    unique ability to adjust quickly to accommodate client’s
                    needs while customizing support packages that deliver
                    results.
                    <br />
                    <br />
                    As the VP of Local Store Marketing for{" "}
                    <a
                      href="http://marcos.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.Marcos.com
                    </a>
                    , he was recognized with the company’s prestigious MVP award
                    and The Person of the Year Award, and forged forever
                    relationships with great mentors. One of those mentors and
                    admirers was CEO Jack Butorac, who had this to say about
                    Jason:
                    <Quote>
                      "Jason's Local Store Marketing approach, particularly his
                      focus on building community relationships, played an
                      important role in Marco's growth from 200+ stores to the
                      900+ stores we are today. His strategies for Grand
                      Openings helped new stores launch strong. But perhaps
                      Jason’s greatest contribution was his ability to help
                      franchisees understand the value of grassroots marketing,
                      great values and changing the way they connect within
                      their communities."
                    </Quote>
                    While with Marco’s Franchising, the brand was recognized as
                    the fastest growing Pizza company in America six years in a
                    row, received the industry’s top honor as the Golden Chain
                    Award Winner and was recognized as “America’s Favorite Pizza
                    Company.” As marketing vice president, Lowder helped the
                    chain grow to over 900 locations in 37 states and 3
                    countries while building a Local Store Marketing department
                    that supported over 600 new store Grand Openings and grew
                    average unit volumes to over $700k.
                    <br />
                    <br />
                    That’s what relationship marketing is all about, and what it
                    can do for you.
                  </p>
                </div>
                <div>
                  <img src={pro} />
                </div>
              </SectionGrid>
            </Section>
            <Wrapper>
              <LogoGrid>
                <div>
                  <img src={jere} />
                </div>
                <div>
                  <img src={marcos} />
                </div>
                <div>
                  <img src={lennar} />
                </div>
                <div>
                  <img src={sw} />
                </div>
                <div>
                  <img src={tony} />
                </div>
                <div>
                  <img src={balance} />
                </div>
              </LogoGrid>
            </Wrapper>
          </Element>
          <Element name="public">
            <Section modifiers={["grey"]}>
              <div className="text-center">
                <h2>The Public Speaker</h2>
                <h3>Captivating</h3>
              </div>
              <SectionGrid>
                <div className="text-center">
                  <img src={speaker} />
                </div>
                <div>
                  <p>
                    This Public Speaker has been hosting events all around the
                    world from for 30 plus years and is able to capture the
                    attention of audiences of all types from white and black tie
                    to corporate convention and destination events as well as
                    motivational key note speaking, event host, master of
                    ceremonies voice model and a Jack of all trades on the
                    microphone making any event more fun, engaging and
                    memorable.
                  </p>
                  <br />
                  <Button modifiers={["white"]} to="contact">
                    Book Jason For Next Event
                  </Button>
                </div>
              </SectionGrid>
            </Section>
          </Element>

          <Element name="contact">
            <Section>
              <div>
                <h2>Just Ask!</h2>
                <h3>See it, Own it, Solve it, Do it!!!</h3>
              </div>
              <SectionGrid>
                <div>
                  <p>
                    Jason is a man of many hats and many talents and has divided
                    his time into 4 quadrants:
                    <h4>The Person</h4>
                    If you want to reach out to Jason on a personal level,
                    please do so by using the link below to email him and / or
                    friend him on Facebook with the link below.
                    <h4>The Passion</h4>
                    Jason's passion is{" "}
                    <a
                      href="http://lowderrelationships.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.LowderRelationships.com
                    </a>{" "}
                    where he is focused on changing this world one relationship
                    at a time by teaching relationship marketing to business
                    owners who want to increase sales through strategically
                    local relationships. Back to the Basics!
                    <h4>The Professional</h4>
                    Easily accessible through several social media channels and
                    his websites, for relationship marketing services at{" "}
                    <a
                      href="http://lowderrelationships.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.LowderRelationships.com
                    </a>{" "}
                    and grassroots marketing services through{" "}
                    <a
                      href="http://fixaim.com"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      www.FixAim.com
                    </a>
                    .<h4>The Public Speaker</h4>
                    Contact him via this email link{" "}
                    <a
                      href="mailto:info@jasonlowder.com"
                      rel="noopner noreferrer"
                    >
                      Info@JasonLowder.com
                    </a>
                    .
                  </p>
                  <h4>Simple and Easy</h4>
                  <p>
                    <a
                      href="mailto: info@jasonlowder.com"
                      rel="noopner noreferrer"
                    >
                      Click Here: Easy@JasonLowder.com
                    </a>
                  </p>
                  <h4>Facebook</h4>
                  <p>
                    <a
                      href={data.site.siteMetadata.social.facebook}
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      Like Jason On Facebook
                    </a>
                  </p>
                  <h4>LinkedIn</h4>
                  <a
                    href={data.site.siteMetadata.social.linkedin}
                    target="_blank"
                    rel="noopner noreferrer"
                  >
                    Find Jason On LinkedIn
                  </a>
                </div>
                <div className="text-right">
                  <img src={jason1} />
                </div>
              </SectionGrid>
            </Section>
          </Element>
        </>
      )}
    />
  </Layout>
)

export default IndexPage
